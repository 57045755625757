import React, { useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
import { clearStorage } from "../utils/storageUtils";
import { useLocation, useNavigate } from "react-router-dom";

function Header({ setRefresh, isClose = false }) {

    const navigate = useNavigate();

    // Dark Mode State
    const [darkMode, setDarkMode] = useState(false);

    // Side Menu State
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const _darkMode = localStorage.getItem("DarkMode");
        console.log("DarkMode", _darkMode);
        if (_darkMode && _darkMode === "true") {
            setDarkMode(true);
        } else {
            setDarkMode(false);
        }
    }, []);

    useEffect(() => {
        if (darkMode) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [darkMode]);

    const location = useLocation();

    const { instance } = useMsal();

    function onClickRefresh() {
        setRefresh((prev) => {
            return !prev
        });
    }

    function onClickDarkMode() {
        toggleMenu();
        setDarkMode((prev) => {
            localStorage.setItem("DarkMode", !prev);
            return !prev
        });
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    function onClickClose() {
        window.close();
    }

    function onClickLogOut() {
        toggleMenu();
        const activeAccount = instance.getActiveAccount();
        clearStorage(activeAccount);

        instance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        })
    }

    const moveTo = (path) => {
        toggleMenu();
        navigate(path);
    }

    return (
        <>
            <nav className="flex flex-row justify-between items-center p-2">
                <div className="cursor-pointer" onClick={onClickRefresh}>
                    Refresh
                </div>
                <div className="font-bold text-2xl">
                    MNA
                </div>
                <div>
                    <button
                        className="flex flex-col items-center justify-center w-10 h-10 space-y-1 focus:outline-none"
                        onClick={toggleMenu}
                    >
                        <div className={`w-8 h-1 rounded bg-black dark:bg-white transition-transform ${isOpen ? 'rotate-45 translate-y-2' : ''}`}></div>
                        <div className={`w-8 h-1 rounded bg-black dark:bg-white transition-opacity ${isOpen ? 'opacity-0' : 'opacity-100'}`}></div>
                        <div className={`w-8 h-1 rounded bg-black dark:bg-white transition-transform ${isOpen ? '-rotate-45 -translate-y-2' : ''}`}></div>
                    </button>
                </div>
            </nav>
            {/* MENU */}
            <div
                className={`z-10 absolute right-1 top-12 w-48 bg-white dark:bg-black text-black dark:text-white border rounded-lg shadow-lg transform transition-all ${isOpen ? 'opacity-100 scale-100' : 'hidden opacity-0 scale-95'}`}
            >
                <ul className="p-4">
                    <li className="p-2 cursor-pointer" onClick={() => { moveTo("/mes/buildstatus/mi/front") }}>Build Status-Front(MI)</li>
                    <li className="p-2 cursor-pointer" onClick={() => { moveTo("/mes/buildstatus/mi/rear") }}>Build Status-Rear(MI)</li>
                    <li className="p-2 cursor-pointer" onClick={() => { moveTo("/mes/production/mi") }}>Production Summary(MI)</li>
                    <li className="p-2 cursor-pointer" onClick={() => { moveTo("/mes/buildstatus/oh") }}>Build Status(OH)</li>
                    <li className="p-2 cursor-pointer" onClick={() => { moveTo("/mes/production/oh") }}>Production Summary(OH)</li>
                </ul>
                <ul className="border-t-2 p-4">
                    <li className="p-2 cursor-pointer" onClick={onClickDarkMode}>{darkMode ? "Light Mode" : "Dark Mode"}</li>
                    <li className="p-2 cursor-pointer" onClick={onClickLogOut}>Sign Out</li>
                </ul>
            </div>
        </>
    );
}

export default Header
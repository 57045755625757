import { Routes, Route, useNavigate } from "react-router-dom";
import { MsalProvider } from '@azure/msal-react';
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";

import Login from "./pages/Login";
import NotFound from "./pages/Error";
import ProductionMi from "./pages/ProductionMi";
import ProductionOh from "./pages/ProductionOh";
import BuildStatusMiFront from "./pages/BuildStatusMiFront";
import BuildStatusMiRear from "./pages/BuildStatusMiRear";
import BuildStatusOh from "./pages/BuildStatusOh";

function App({ instance }) {
  const userToken = useSelector((state) => state?.user?.token);
  const navigate = useNavigate();

  useEffect(() => {
    if (userToken === "error") {
      navigate("/");
      const account = instance.getActiveAccount();
      if (account) {
        console.log("account", account);
        const logoutRequest = {
          // idTokenHint: accessToken,
          account: account,
          postLogoutRedirectUri: "/",
          mainWindowRedirectUri: "/",
        };
        instance.logoutRedirect(logoutRequest).catch((e) => {
          console.error(e);
        });
      }
    }
  }, [userToken]);

  return (
    <MsalProvider instance={instance}>
      <Routes>
        <Route>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route>
          <Route exact path="/mes/module" element={<ProductionMi />} />
          <Route exact path="/mes/production/mi" element={<ProductionMi />} />
          <Route exact path="/mes/production/oh" element={<ProductionOh />} />
          <Route exact path="/mes/buildstatus/mi/front" element={<BuildStatusMiFront />} />
          <Route exact path="/mes/buildstatus/mi/rear" element={<BuildStatusMiRear />} />
          <Route exact path="/mes/buildstatus/oh" element={<BuildStatusOh />} />
        </Route>
      </Routes>
    </MsalProvider>
  );
}

export default App;
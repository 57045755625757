import React, { useEffect, useState } from 'react';

import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import Header from '../component/Header';
import Spinner from '../component/Spinner';
import { getProductionData } from '../api/auth';
import dayjs from 'dayjs';

function ProductionOh() {

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const navigate = useNavigate();

    const [isShow, setIsShow] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [date, setDate] = useState('');
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!activeAccount) {
            navigate("/mes");
        }
    }, [activeAccount]);

    useEffect(() => {
        (async () => {
            try {
                setIsShow(true);
                const result = await getProductionData("OH");
                console.log('result', result);
                if (result.ok === true) {
                    setData(result.data);
                    setDate(result.date);
                } else {
                    alert("API CALL FAIL");
                }
            } catch (error) {
                console.log('error', error);
                alert(error.message);
            } finally {
                setIsShow(false);
            }
        })();
    }, [refresh]);

    return (
        <>
            <Spinner isShow={isShow} />
            <Header setRefresh={setRefresh} />
            <div className='p-1 text-md bg-white dark:bg-black text-black dark:text-white'>
                <div className="flex flex-row justify-between">
                    <div className="font-bold">
                        Production Summary(OH)
                    </div>
                    <div className={`${((new Date().getTime() - new Date(date).getTime()) / (1000 * 60)) > 10 ? "bg-red-500" : ""}`}>
                        {dayjs(date).format('MMM, D YYYY(ddd) HH:mm A')}
                    </div>
                </div>
                <div className="relative overflow-x-auto">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <thead className="text-xs bg-white dark:bg-black text-black dark:text-white ">
                            <tr className="bg-gray-300 dark:bg-[#1D3FA3]">
                                <th scope="col" className="border py-1">

                                </th>
                                <th scope="col" className="border py-1">
                                    TARGET
                                </th>
                                <th scope="col" className="border py-1">

                                </th>
                                <th scope="col" className="border py-1">
                                    RESULT
                                </th>
                                <th scope="col" className="border py-1">
                                    GAP
                                </th>
                                <th scope="col" className="border py-1">
                                    RATE(%)
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            {data && data.map((v, i) => {
                                return i % 2 === 0 ? (
                                    <React.Fragment key={i}>
                                        <tr className={`bg-white dark:bg-black text-black dark:text-white border-b`}>
                                            <td scope="row" rowSpan={2} className={`border py-1 font-medium bg-gray-300 dark:bg-[#1D3FA3]`}
                                                style={{ width: '18%' }}>
                                                {v.DATA_KIND}
                                            </td>
                                            <td className="border py-1" rowSpan={2} style={{ width: '18%' }}>
                                                {v.TARGET}
                                            </td>
                                            <td className="border py-1" style={{ width: '13%' }}>
                                                {v.DATA_LOCATION}
                                            </td>
                                            <td className="border py-1" style={{ width: '17%' }}>
                                                {v.RESULT}
                                            </td>
                                            <td className="border py-1" style={{ width: '17%' }}>
                                                {v.GAP}
                                            </td>
                                            <td className="border py-1" style={{ width: '17%' }}>
                                                {v.RATE}%
                                            </td>
                                        </tr>
                                        <tr className={`bg-white dark:bg-slate-800 text-blue-700 dark:text-blue-500 border-b`}>
                                            <td className="border py-1" style={{ width: '13%' }}>
                                                {data?.[i + 1].DATA_LOCATION}
                                            </td>
                                            <td className="border py-1" style={{ width: '17%' }}>
                                                {data?.[i + 1].RESULT}
                                            </td>
                                            <td className="border py-1" style={{ width: '17%' }}>
                                                {data?.[i + 1].GAP}
                                            </td>
                                            <td className="border py-1" style={{ width: '17%' }}>
                                                {data?.[i + 1].RATE}%
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ) : null
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ProductionOh;

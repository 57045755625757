import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userSlice from './slices/userSlice';

const persistConfig = {
    key: 'store',
    storage,
    whitelist: ['user'],
};

export const rootReducer = combineReducers({
    user: userSlice,
});

export const persistRootStore = persistReducer(persistConfig, rootReducer);
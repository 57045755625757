import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import store from '../redux/store';
import { setToken } from '../redux/slices/userSlice';
import { msalInstance } from '../index';

/**
 * The withCredentials property is often used when you need to make requests to
 * a different domain (cross-origin requests) and want to include credentials such as 
 * cookies in those requests.
 */
axios.defaults.withCredentials = true;

export const client = axios.create({
  baseURL: 'http://localhost:4000/api',
  // baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Intercept all requests
client.interceptors.request.use(
  async (config) => {
    try {
      const currentAccount = msalInstance.getActiveAccount();
      const accessTokenRequest = {
        ...loginRequest,
        account: currentAccount
      };

      const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);

      if (accessTokenResponse.accessToken) {
        config.headers.Authorization = `Bearer ${accessTokenResponse.accessToken}`;
        config.headers.version = "1.0.3";
      }
    } catch (err) {
      console.error(err);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Intercept all responses
client.interceptors.response.use(
  async response => {
    return response;
  },
  error => {
    if (error?.response?.status) {
      console.log('[API ERROR] CODE : ', error.response.status, ' MESSAGE : ', error.response.data.err);
      if (error.response.status === 401) {
        alert("Unauthorized request. Please log in again.");
        // auth error, go to login
        store.dispatch(
          setToken({ token: "error" })
        );
      } else if (error.response.status === 403) {
        alert("The application has been updated.");
        window.location.reload();
      }
    } else {
      return Promise.reject(error);
    }
  },
);

export const login = async () => {
  try {
    const response = await client.post('/data/login');
    return response.data ?? [];
  } catch (err) {
    throw new Error(err.response?.data?.err ?? err.message);
  }
};

export const getModuleData = async () => {
  try {
    const response = await client.get('/data/module');
    return response.data ?? [];
  } catch (err) {
    throw new Error(err.response?.data?.err ?? err.message);
  }
};

export const getPlasticData = async () => {
  try {
    const response = await client.get('/data/plastic');
    return response.data ?? [];
  } catch (err) {
    throw new Error(err.response?.data?.err ?? err.message);
  }
};

export const getIpData = async () => {
  try {
    const response = await client.get('/data/ip');
    return response.data ?? [];
  } catch (err) {
    throw new Error(err.response?.data?.err ?? err.message);
  }
};

export const getGAMainData = async () => {
  try {
    const response = await client.get('/data/main');
    return response.data ?? [];
  } catch (err) {
    throw new Error(err.response?.data?.err ?? err.message);
  }
};

export const getGASubData = async () => {
  try {
    const response = await client.get('/data/sub');
    return response.data ?? [];
  } catch (err) {
    throw new Error(err.response?.data?.err ?? err.message);
  }
};

export const getGAShipItem = async (accessToken, plant, ifkdt, seqhd) => {
  try {
    const response = await client.get('/data/gashipitem', {
      headers: { 'version': '1.0.3', 'Authorization': `Bearer ${accessToken}` },
      params: {
        plant, ifkdt, seqhd
      }
    });
    return response.data ?? [];
  } catch (err) {
    throw new Error(err.response?.data?.err ?? err.message);
  }
};

export const getRTMSData = async (accessToken) => {
  try {
    const response = await client.get('/data/rtms', { headers: { 'version': '1.0.3', 'Authorization': `Bearer ${accessToken}` } });
    return response.data ?? [];
  } catch (err) {
    throw new Error(err.response?.data?.err ?? err.message);
  }
};

export const vaildateToken = async (accessToken) => {
  try {
    const response = await client.get('/data/vaildateToken', { headers: { 'version': '1.0.3', 'Authorization': `Bearer ${accessToken}` } });
    return response.data ?? [];
  } catch (err) {
    throw new Error(err.response?.data?.err ?? err.message);
  }
};

export const getBuildStatusData = async (plant) => {
  try {
    const response = await client.get('/data/buildStatus', {
      params: {
        plant
      }
    });
    return response.data ?? [];
  } catch (err) {
    throw new Error(err.response?.data?.err ?? err.message);
  }
};

export const getProductionData = async (plant) => {
  try {
    const response = await client.get('/data/production', {
      params: {
        plant
      }
    });
    return response.data ?? [];
  } catch (err) {
    throw new Error(err.response?.data?.err ?? err.message);
  }
};
import React, { useEffect, useState } from 'react';

import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import Header from '../component/Header';
import Spinner from '../component/Spinner';
import { getBuildStatusData } from '../api/auth';
import dayjs from 'dayjs';

function BuildStatusOh() {

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const navigate = useNavigate();

    const [isShow, setIsShow] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [dataHeader, setDataHeader] = useState(null);
    const [date, setDate] = useState('');
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);

    useEffect(() => {
        if (!activeAccount) {
            navigate("/mes");
        }
    }, [activeAccount]);

    useEffect(() => {
        (async () => {
            try {
                setIsShow(true);
                const result = await getBuildStatusData("OH");
                console.log('result', result);
                if (result.ok === true) {
                    setDataHeader(result?.data?.filter(v => v?.ITEM === "TITLE01" || v?.ITEM === "T01_VALUE"));
                    setData(result?.data?.filter(v => v?.ROW_IDX >= 3 && v?.ROW_IDX <= 8));
                    setData2(result?.data?.filter(v => v?.ITEM?.includes("ALARM")));
                    setDate(result.date);
                } else {
                    alert("API CALL FAIL");
                }
            } catch (error) {
                console.log('error', error);
                alert(error.message);
            } finally {
                setIsShow(false);
            }
        })();
    }, [refresh]);

    return (
        <>
            <Spinner isShow={isShow} />
            <Header setRefresh={setRefresh} />
            <div className='p-1 text-md bg-white dark:bg-black text-black dark:text-white'>
                <div className="flex flex-row justify-between">
                    <div className="font-bold">
                        Build Status (OH)
                    </div>
                    <div className={`${((new Date().getTime() - new Date(date).getTime()) / (1000 * 60)) > 10 ? "bg-red-500" : ""}`}>
                        {dayjs(date).format('MMM, D YYYY(ddd) HH:mm A')}
                    </div>
                </div>
                <div className="relative overflow-x-auto mb-2">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            <tr className="bg-gray-300 dark:bg-[#1D3FA3]">
                                <th scope="col" className="border py-2" style={{ width: '25%' }}>
                                    {dataHeader?.[0]?.VALUE01}
                                </th>
                                <th scope="col" className="border py-2" style={{ width: '25%' }}>
                                    {dataHeader?.[0]?.VALUE02}
                                </th>
                                <th scope="col" className="border py-2" style={{ width: '25%' }}>
                                    {dataHeader?.[0]?.VALUE03}
                                </th>
                                <th scope="col" className="border py-2" style={{ width: '25%' }}>
                                    {dataHeader?.[0]?.VALUE04}
                                </th>
                            </tr>
                            <tr>
                                <td className="border py-2" style={{ width: '25%' }}>
                                    {dataHeader?.[1]?.VALUE01}
                                </td>
                                <td className="border py-2" style={{ width: '25%' }}>
                                    {dataHeader?.[1]?.VALUE02}
                                </td>
                                <td className="border py-2" style={{ width: '25%' }}>
                                    {dataHeader?.[1]?.VALUE03}
                                </td>
                                <td className="border py-2" style={{ width: '25%' }}>
                                    {dataHeader?.[1]?.VALUE04}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="relative overflow-x-auto">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <thead className="text-xs bg-white dark:bg-black text-black dark:text-white ">
                            <tr className="bg-gray-300 dark:bg-[#1D3FA3]">
                                <th scope="col" className="border py-2">

                                </th>
                                <th scope="col" className="border py-2">
                                    {data?.[0]?.VALUE01}
                                </th>
                                <th scope="col" className="border py-2">
                                    {data?.[0]?.VALUE02}
                                </th>
                                <th scope="col" className="border py-2">
                                    {data?.[0]?.VALUE03}
                                </th>
                                <th scope="col" className="border py-2">
                                    {data?.[0]?.VALUE04}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            {data && data.map((v, i) => {
                                return i > 0 ? (
                                    <tr key={i} className={`bg-white dark:bg-black text-black dark:text-white border-b`}>
                                        <td scope="row" className={`border py-2 font-medium bg-gray-300 dark:bg-[#1D3FA3]`}
                                            style={{ width: '20%' }}>
                                            {v.ITEM}
                                        </td>
                                        <td className="border py-2" style={{ width: '20%' }}>
                                            {v.VALUE01}
                                        </td>
                                        <td className="border py-2" style={{ width: '20%' }}>
                                            {v.VALUE02}
                                        </td>
                                        <td className="border py-2" style={{ width: '20%' }}>
                                            {v.VALUE03}
                                        </td>
                                        <td className="border py-2" style={{ width: '20%' }}>
                                            {v.VALUE04}
                                        </td>
                                    </tr>
                                ) : null
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="relative overflow-x-auto mt-2">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            {data2 && data2.map((v, i) => {
                                return (
                                    <tr key={i} className={`bg-white dark:bg-black text-black dark:text-white border-b`}>
                                        <td scope="row" className={`border py-2 font-medium bg-gray-300 dark:bg-[#1D3FA3]`} style={{ width: '20%' }}>
                                            {v.ITEM}
                                        </td>
                                        <td className="border py-2" style={{ width: '80%' }}>
                                            {v.VALUE01}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default BuildStatusOh;
